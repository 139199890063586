.alertWrapper {
  font-size: 1em !important;
  font-weight: 400 !important;
  font-family: 'Poppins', sans-serif !important;
}

.alertWrapperTitle {
  font-size: 1.05em !important;
  font-weight: 600 !important;
  font-family: 'Poppins', sans-serif !important;
}
