.shelf-flexbox-wrapper {
  grid-area: shelf;
  width: 100%;
}

.shelf-flexbox {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

/* We tell all items to be 100% width, via flex-basis */
.shelf-flexbox > * {
  display: flex;
  flex: 1 1 calc(100% / 250);
  /* The next line resizes the film-spine-flexbox-wrapper 
    It sits at 260 to stop the content from overflowing. 
    Is this a good fix? Who's to say. But it works.*/
  width: calc(100% / 260);
  // flex-shrink: 1;
}

.film-spine-flexbox-wrapper:active {
  transform: scale(1, 1.1);
  // box-shadow: 0px 0px 10px 2px #98714e;
  width: 6vw;
  // blank comment intentional to force push //
}

@media screen and (min-width: 1000px) {
  .film-spine-flexbox-wrapper-active {
    z-index: 2;
  }
  .film-spine-text-flexbox {
    box-shadow: 0px 0px 10px 2px #98714e;
  }
  .film-spine-text-flexbox:after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      var(--spine-bg-color) 90%
    );
    width: 100%;
    height: 2em;
  }
}

.film-spine-flexbox-wrapper-active {
  transform: scale(1, 1.1);
  // box-shadow: 0px 0px 10px 2px #98714e;
  width: 6vw;
  z-index: 99;
}

.film-spine-flexbox-wrapper:hover {
  display: flex;
  transform: scale(1, 1.1);
  // box-shadow: 0px 0px 10px 2px #98714e;
  width: 6vw !important;
}

.film-spine-flexbox {
  background-color: inherit;
  width: 100%;
  height: clamp(250px, 30vh, 400px);
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transition: var(--spine-transition-duration);
}

.film-spine-text-flexbox {
  background-color: inherit;

  padding-top: 2.5%;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  /* transition: var(--spine-transition-duration); */
}

:root {
  --spine-bg-color: #2b2b2b;
}

.film-spine-flexbox-wrapper-active .film-spine-text-flexbox {
  z-index: 100;
  opacity: 1;
  visibility: visible;
}

.film-spine-flexbox-wrapper:hover .film-spine-text-flexbox {
  z-index: 100;
  opacity: 1;
  visibility: visible;
}

.shelf-labels {
  grid-area: labels;
  display: grid;
  grid-template-columns: 30% 40% 30%;
  grid-template-areas: 'label-l . label-r';
  margin-top: 10px;
  font-weight: 600;
}

.shelf-labels-mob {
  grid-area: labels-mob;
  display: grid;
  grid-template-columns: 10% 80% 10%;
  grid-template-areas: 'label-l . label-r';
  font-weight: 600;
  margin-bottom: -10px;
}

#shelf-label-l {
  grid-area: label-l;
  text-align: left;
}

#shelf-label-r {
  grid-area: label-r;
  text-align: right;
}

// Tablet
@media screen and (max-width: 1000px) and (min-width: 501px) {
  .shelf-flexbox-wrapper {
    width: 100%;
    overflow-x: scroll !important;
    border-radius: 0.25em;
  }
  .shelf-flexbox {
    width: calc(23px * 250) !important;
  }
  .film-spine-text-flexbox {
    visibility: visible !important;
    position: relative;
    opacity: 1 !important;
    font-size: 0.9em !important;
    letter-spacing: 0.03em;
    /* transition: var(--spine-transition-duration); */
  }
  .film-spine-flexbox {
    border: 1px solid rgba(152, 113, 78, 0.2);
  }
  .film-spine-flexbox-wrapper:active {
    transform: scale(1.1, 1) !important;
  }

  .film-spine-text-flexbox:after {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      var(--spine-bg-color) 90%
    );
    width: 100%;
    height: 2em;
  }

  .film-spine-flexbox-wrapper-active {
    position: sticky !important;
    transform: scale(1.1, 1) !important;
    box-shadow: 0px 0px 10px 2px #98714e;
  }

  .film-spine-flexbox-wrapper:hover {
    transform: scale(1.1, 1) !important;
    box-shadow: 0px 0px 10px 1px #98714e;
    z-index: 5;
  }
}

// Mobile
@media screen and (max-width: 500px) {
  .shelf-flexbox-wrapper {
    width: 100% !important;
    // overflow-y: scroll !important;
    border-radius: 0.25em;
    z-index: 5;

    -moz-box-shadow: inset 0 0 10px #000000;
    -webkit-box-shadow: inset 0 0 10px #000000;
    box-shadow: inset 0 0 10px #000000;
  }

  .shelf-flexbox {
    flex-flow: column nowrap !important;
    overflow-y: scroll !important;
    height: 33vh !important;
    width: 100% !important;
  }

  .film-spine-flexbox-wrapper,
  .film-spine-flexbox-wrapper-active {
    width: 100% !important;
    height: auto !important;
  }

  .film-spine-flexbox-wrapper:active {
    transform: scale(1, 1.02) !important;
  }
  .film-spine-flexbox-wrapper-active {
    position: sticky !important;
    transform: scale(1, 1.02) !important;
    box-shadow: 0px 0px 10px 2px #98714e;
  }

  .film-spine-flexbox-wrapper:hover {
    transform: scale(1, 1.02) !important;
    box-shadow: 0px 0px 10px 1px #98714e;
    z-index: 5;
    width: auto !important;
    height: auto !important;
  }

  .film-spine-flexbox {
    border: 1px solid rgba(152, 113, 78, 0.2);
    writing-mode: inherit !important;
    height: 31px !important;
    width: 100% !important;
  }

  .film-spine-text-flexbox {
    visibility: visible !important;
    position: relative;
    opacity: 1 !important;
    font-size: 0.9em !important;
    margin-left: 5px;
    line-height: 1.1;
    letter-spacing: 0.03em;
    /* transition: var(--spine-transition-duration); */
  }

  .film-spine-text-flexbox:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    pointer-events: none;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 85%,
      var(--spine-bg-color) 100%
    );
    width: 100%;
    height: 100%;
  }
}
