.shelf-menu-wrapper {
  grid-area: top-menu;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  margin-top: 15px;
}

.shelf-menu {
  display: flex;
  flex-wrap: nowrap;
  justify-content: left;
  line-height: 2.2em !important;
}

@media screen and (max-width: 686px) {
  .h2 {
    font-size: 1em !important;
  }
  .MuiBox-root {
    margin-top: -10px;
    vertical-align: bottom !important;
  }
}

.controls {
  grid-area: controls;
  display: flex;
  flex-wrap: nowrap;
  justify-content: right;
  align-items: center;
  margin-top: 0.6em;
}

@media screen and (max-width: 1000px) {
  .controls {
    margin-top: -8px;
    margin-bottom: 20px;
  }

  .MuiSelect-select {
    font-size: calc(1.325rem + 0.9vw) !important;
    min-height: calc(1.625rem + 0.9vw) !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500 !important;
    line-height: auto !important;
    padding-bottom: 0pt !important;
  }
}

.control-button {
  margin: 5px;
}

.MuiFormControl-root {
  vertical-align: bottom !important;
}

@media screen and (min-width: 1000px) {
  .MuiSelect-select {
    color: whitesmoke !important;
    font-size: 2.1em !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500 !important;
    line-height: auto !important;
    padding-bottom: 0pt !important;
    min-height: 1em !important;
  }
}

.MuiInputLabel-root,
.MuiSelect-icon,
.MuiSelect-standard,
.MuiMenuItem-root {
  color: #f5f5f5 !important;
}

.MuiInput-underline:before {
  border-bottom-color: whitesmoke !important;
}

.Mui-selected {
  background-color: rgba(245, 124, 0, 0.8) !important;
}

.MuiMenuItem-root:hover {
  background-color: rgba(245, 124, 0, 0.6) !important;
}

.MuiInputBase-root:after {
  border-bottom-color: rgb(245, 124, 0) !important;
}
